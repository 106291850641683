import { render, staticRenderFns } from "./FeedCard.vue?vue&type=template&id=24d7d00d&scoped=true&"
import script from "./FeedCard.vue?vue&type=script&lang=js&"
export * from "./FeedCard.vue?vue&type=script&lang=js&"
import style0 from "./FeedCard.vue?vue&type=style&index=0&id=24d7d00d&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24d7d00d",
  null
  
)

export default component.exports